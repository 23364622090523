import _randomBytes from "random-bytes";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Module dependencies.
 * @private
 */
var randomBytes = _randomBytes;
/**
 * Module variables.
 * @private
 */

var EQUAL_END_REGEXP = /=+$/;
var PLUS_GLOBAL_REGEXP = /\+/g;
var SLASH_GLOBAL_REGEXP = /\//g;
/**
 * Module exports.
 * @public
 */

exports = uid;
exports.sync = uidSync;
/**
 * Create a unique ID.
 *
 * @param {number} length
 * @param {function} [callback]
 * @return {Promise}
 * @public
 */

function uid(length, callback) {
  // validate callback is a function, if provided
  if (callback !== undefined && typeof callback !== "function") {
    throw new TypeError("argument callback must be a function");
  } // require the callback without promises


  if (!callback && !_global.Promise) {
    throw new TypeError("argument callback is required");
  }

  if (callback) {
    // classic callback style
    return generateUid(length, callback);
  }

  return new Promise(function executor(resolve, reject) {
    generateUid(length, function onUid(err, str) {
      if (err) return reject(err);
      resolve(str);
    });
  });
}
/**
 * Create a unique ID sync.
 *
 * @param {number} length
 * @return {string}
 * @public
 */


function uidSync(length) {
  return toString(randomBytes.sync(length));
}
/**
 * Generate a unique ID string.
 *
 * @param {number} length
 * @param {function} callback
 * @private
 */


function generateUid(length, callback) {
  randomBytes(length, function (err, buf) {
    if (err) return callback(err);
    callback(null, toString(buf));
  });
}
/**
 * Change a Buffer into a string.
 *
 * @param {Buffer} buf
 * @return {string}
 * @private
 */


function toString(buf) {
  return buf.toString("base64").replace(EQUAL_END_REGEXP, "").replace(PLUS_GLOBAL_REGEXP, "-").replace(SLASH_GLOBAL_REGEXP, "_");
}

export default exports;
export const sync = exports.sync;